import {AppHeaderEN} from "../components/headerEN";
import {AppHeader} from "../components/header";
import React from "react";

export const Main = (id:string, utm_source:string, language:string) =>{
    let urlV = 'viber://pa?chatURI=cgc_vetbot';
    let parseSpace = false;
    if (utm_source != '' || id !=''){
        urlV = urlV + '&context=';
        if (utm_source != ''){
            if (parseSpace) {
                urlV = urlV + '-';
            }
            urlV = urlV +'promo_'+utm_source;
            parseSpace = true;

        }
        if (id != ''){
            if (parseSpace) {
                urlV = urlV + '-';
            }
            urlV = urlV +'id_'+id;
            parseSpace = true;
        }
    }

    let urlT = 'https://t.me/cgc_vetbot';
    parseSpace = false;
    if (utm_source != '' || id !=''){
        urlT = urlT + '?start=';
        if (utm_source != ''){
            if (parseSpace) {
                urlT = urlT + '-';
            }
            urlT = urlT +'promo_'+utm_source;
            parseSpace = true;

        }
        if (id != ''){
            if (parseSpace) {
                urlT = urlT + '-';
            }
            urlT = urlT +'id_'+id;
            parseSpace = true;
        }
    }

    return (


       <div className='App-header'>
           {language === 'en' ? <AppHeaderEN utm={utm_source}/> : <AppHeader utm={utm_source}/>}
           <div className="div">
               <a className = 'btnPromote' href={urlT}>
                   <div className="divBtPromote" ></div>
                   <p className="txtBtPromote">Telegram</p>
               </a>
           </div>
           <div className="div">
               <a className = 'btnPromote' href={urlV}>
                   <div className="divBtPromote" ></div>
                   <p className="txtBtPromote">Viber</p>
               </a>
           </div>

        </div>
        )

}
