import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes, useSearchParams} from "react-router-dom";
import  { telegram }  from "./pages/telegram";
import {Main} from "./pages/Main";
import {viber} from "./pages/viber";
import {promoter} from "./pages/promoter";
import {AppHeader} from "./components/header";
import {info} from "./pages/info";
import {consumer} from "./pages/consumer";
import {anket} from "./pages/anket";
import {AppHeaderEN} from "./components/headerEN";
import {infokozin} from "./pages/infokozin";
import {viberkozin} from "./pages/viberkozin";
import  { telegramkozin }  from "./pages/telegramkozin";



function App() {
    const [searchParams, setSearchParams] = useSearchParams();
    const id:string = searchParams.get('id') || '';
    const utm_source:string = searchParams.get('utm_source') || '';

    const utm_medium:string = searchParams.get('utm_medium') || '';
    const promo = searchParams.get('promo') || '';
    const language = searchParams.get('lang') || '';
    const userId = encodeURIComponent(window.navigator.userAgent);


    useEffect(() => {

        const url1 = 'https://checkbox.hab.vet/botsget?utm_source='+utm_source+'&utm_medium='+utm_medium+'&userId='+userId;
        //console.log(fetch(url1));
        fetch(url1)
            .then(response => response.json())
            .catch(error => console.error(error));

        /*
        const sendUtmData = async () => {
            const url = 'https://checkbox.hab.vet/botsget';
            const info = {
                utm_source: utm_source,
                userId: userId,
                utm_medium: utm_medium

            };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(info),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();
                console.log('Success:', responseData);
            } catch (error) {
                console.error('Error sending UTM data:', error);
            }
        };

        // Вызываем функцию
        sendUtmData();*/
    }, []);

  return (
<div className="App-headervTwo">

          <Routes>
              <Route path='/' element={ Main(id, utm_source, language) }/>
              <Route path='/telegram' element={ telegram(id, utm_source, language) } />
              <Route path='/viber' element={ viber(id, utm_source, language) } />
              <Route path='/promoter' element={ promoter(id, utm_source) } />
              <Route path='/info' element={ info(id, utm_source, language) } />
              <Route path='/consumer' element={ consumer(id, utm_source) } />
              <Route path='/anket' element={ anket(id, utm_source) } />
              <Route path='/infokozin' element={ infokozin(id, utm_source, language) } />
              <Route path='/telegramkozin' element={ telegramkozin(id, utm_source, language) } />
              <Route path='/viberkozin' element={ viberkozin(id, utm_source, language) } />
          </Routes>
</div>
  );
}

export default App;
