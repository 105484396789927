import {AppHeaderEN} from "../components/headerEN";
import {AppHeader} from "../components/header";
import React from "react";


export const info = (id:string, utm_source:string, language:string) =>{

    return (
        <div className="App-header">
            {language === 'en' ? <AppHeaderEN utm={utm_source}/> : <AppHeader utm={utm_source}/>}
            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/telegram?id='+id+'&utm_source='+utm_source+'&lang='+language}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Telegram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/viber?id='+id+'&utm_source='+utm_source+'&lang='+language}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Viber</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.instagram.com/hab_vet/'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Instagram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.hab.vet/?utm_medium=bots&utm_source='+utm_source}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">{language === 'en' ? "Site" : "Сайт"}</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://goo.gl/maps/GFzPZ5kViLuaEAL58'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">{language === 'en' ? "Google maps" : "Мапа"}</p>
                </a>
            </div>

        </div>  )

}
