import {AppHeaderENkozin} from "../components/headerENkozin";
import {AppHeaderkozin} from "../components/headerkozin";
import React from "react";


export const infokozin = (id:string, utm_source:string, language:string) =>{

    return (
        <div className="App-header">
            {language === 'en' ? <AppHeaderENkozin utm={utm_source}/> : <AppHeaderkozin utm={utm_source}/>}
            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/telegramkozin?id='+id+'&utm_source='+utm_source+'&lang='+language}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Telegram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://bots.hab.vet/viberkozin?id='+id+'&utm_source='+utm_source+'&lang='+language}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Viber</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.instagram.com/hab_vet/'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">Instagram</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://www.hab.vet/?utm_medium=botskozin&utm_source='+utm_source}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">{language === 'en' ? "Site" : "Сайт"}</p>
                </a>
            </div>
            <div className="div">
                <a className = 'btnPromote' href={'https://maps.app.goo.gl/xtJGjzmq58E3ptJQ6'}>
                    <div className="divBtPromote"></div>
                    <p className="txtBtPromote">{language === 'en' ? "Google maps" : "Мапа"}</p>
                </a>
            </div>

        </div>  )

}
